.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.4);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.2s 0s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.2s 0s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.2s 0s forwards;
  animation: react-confirm-alert-fadeIn 0.2s 0s forwards;
}

.react-confirm-alert {
  font-family: Helvetica, sans-serif;
  width: 80%;
  padding: 30px;
  text-align: left;
  background: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #333;
  text-align: center;
  position: relative;
  opacity: 0;
  transform: scale(0.6);
  margin-top: -50px;
  -webkit-animation: react-confirm-alert-bounce 0.2s 0.1s forwards;
  -moz-animation: react-confirm-alert-bounce 0.2s 0.1s forwards;
  -o-animation: react-confirm-alert-bounce 0.2s 0.1s forwards;
  animation: react-confirm-alert-bounce 0.2s 0.1s forwards;
}

.react-confirm-alert > h1 {
  margin-top: 0;
  font-family: $ff-regular;
  text-transform: uppercase;
  font-size: 1.4rem;
  letter-spacing: 1px;
  font-weight: normal;
}

.react-confirm-alert > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert div.react-confirm-description {
  line-height: 1;
  margin: 0;
  margin-top: 15px;
  padding-bottom: 40px;
  p {
    line-height: 1;
    font-size: 1.2rem;
    font-family: $ff-light;
    letter-spacing: 0.5px;
    font-weight: normal;
    text-transform: uppercase;
  }
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  border-top: 1px solid #DDD;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: transparent;
  border: none;
  display: inline-block;
  padding: 14px 18px;
  padding-bottom: 15px;
  padding-top: 13px;
  color: #232323;
  margin-right: 0;
  border-radius: 5px;
  font-size: 15px;
  width: 50%;

  &:first-child {
    border-radius: 0px 0px 0px 10px;
    border-right: 1px solid #DDD;
    color: #FF0000;
  }

  &:last-child {
    border-radius: 0px 0px 10px 0px;
  }
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes react-confirm-alert-bounce {
  from {
    transform: scale(0.6);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-bounce {
  from {
    transform: scale(0.6);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-bounce {
  from {
    transform: scale(0.6);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes react-confirm-alert-bounce {
  from {
    transform: scale(0.6);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
