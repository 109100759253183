.scanner-container {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #232323;

  .spinner {
    display: none;
		position: absolute;
		top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    margin-top: -68px;
		opacity: 1;
    transform: translateX(-50%);
		&:before {
			width: 50px;
			height: 50px;
			border: 1px solid #444;
			border-top-color: #FFF;
		}
	}
}

.scanner__no-support {
  position: absolute;
	top: 40%;
	width: 100%;
	text-align: center;
	line-height: 1;
	font-family: "Icons16", sans-serif;
	font-size: 16px;
	font-weight: 400;
	font-style: normal;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	z-index: 1;
	line-height: 30px;
	color: #232323;
	span {
		display: inline-block;
		@include font-no-results();
	}
}

.scan-complete-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100000;
  background-color: #232323;

  opacity: 0;
  pointer-events: none;
  transition: opacity 0.1s ease-out 0.09s;

  .scan-complete-overlay__icon {
    position: absolute;
    display: block;
    width: 208px;
    height: 208px;
    top: 45%;
    left: 50%;
    margin-top: -104px;
    margin-left: -104px;
    z-index: 100;
    border-radius: 1000px;
    overflow: hidden;
    background-color: transparent;
    cursor: pointer;
    border: 2px solid #79e87e;
    box-sizing: content-box;

    transition: top 0.1s ease-out 0.02s;
    &.scan-complete-overlay__icon--invalid {
      border: 2px solid #ff2a00;
    }
  }

  .scan-complete-overlay__caption,
  .scan-complete-overlay__caption--error {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 50px;
    @include font-ticket-overlay-caption();
    color: $c-ticket-success;
    top: calc(41% + 112px);
    transition: top 0.09s ease-out;

    span {
      position: absolute;
      width: 100%;
      bottom: 45px;
      left: 0;
      opacity: 0;
      transition: bottom 0.09s ease-out, opacity 0.05s ease-out 0.1s;
    }
  }

  .scan-complete-overlay__caption--error {
    color: $c-ticket-error;
  }

  .scan-complete-overlay__msg {
    color: #FFF;
    position: absolute;
    width: 100%;
    top: calc(38% + 163px);
    left: 0;
    opacity: 0;
    text-align: center;
    @include font-ticket-overlay-msg();
    transition: opacity 0.05s ease-out;
  }

  &.animate-in {
    pointer-events: auto;
    opacity: 1;
    transition: opacity 0s ease-out;

    .scan-complete-overlay__icon {
      transition: top 0.3s ease-out;
      opacity: 1;
      top: 38%;
    }

    .scan-complete-overlay__caption,
    .scan-complete-overlay__caption--error {
      transition: top 0.3s ease-out;
      opacity: 1;
      top: calc(38% + 112px);

      span {
        transition: bottom 0.2s ease-out 0.1s;
        bottom: 0;
        opacity: 1;
      }
    }

    .scan-complete-overlay__msg {
      opacity: 1;
      transition: opacity 0.2s ease-out 0.3s;
    }
  }
}
