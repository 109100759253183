
.event-attendees {
	position: relative;
	width: 100%;
	height: 100%;
}

.attendees-filter-bar {
	background-color: #FFF;
	position: relative;
	width: 100%;
	height: 100px;

	&.attendees-filter-bar--search {
		.attendees-filter-bar__search {
			width: calc(100% - 50px);
			.pt-input-group {
				.pt-icon-search {
					left: 0;
					transform: translateX(0);
				}
				.pt-input {
					transform: translateX(0);
				}
			}
		}
	}
}

.attendees-filter-bar__search-cancel {
	position: fixed;
	top: 24px;
	right: 6px;
	z-index: 0;
	line-height: 1;
	padding: 10px;
	cursor: pointer;
}

.attendees-filter-bar__search {
	position: relative;
	padding: 13px;
  padding-top: 26px;
	width: 100%;
	transition: width 0.2s ease;
	.pt-input-group {
		background-color: #eaebed;
		border-radius: 4px;
		z-index: 1;
		.pt-icon-search {
			left: 50%;
			transform: translateX(-50px);
			transition: transform 0.2s ease, left 0.2s ease;
		}
		.pt-input {
			transform: translateX(calc(50% - 50px));
    	width: 100%;
			transition: transform 0.2s ease;
		}
	}
}

.attendees-filter-bar__type {
	position: absolute;
	width: 100%;
	bottom: 0;
	margin-top: 4px;
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		height: 1px;
		width: 100%;
		background-color: #e5e5e5;
	}
}

.attendees-list,
.attendees-search-list {
	position: relative;
	width: 100%;
	height: calc(100% - 100px);
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	ul {
		padding-bottom: 60vh;
	}
	li {
		position: relative;
    width: calc(100% - 20px);
    padding-top: 23px;
    padding-bottom: 19px;
    margin-left: 20px;
    border-bottom: 1px solid #eaeaea;
		cursor: pointer;
		@include font-list-item();
	}
}

.attendees__no-results {
	position: absolute;
	top: 35%;
	width: 100%;
	text-align: center;
	line-height: 1;
	font-family: "Icons16", sans-serif;
	font-size: 16px;
	font-weight: 400;
	font-style: normal;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	z-index: 1;
	line-height: 30px;
	color: #232323;
	span {
		display: inline-block;
		@include font-no-results();
	}
	&:before {
		display: inline-block;
    width: 100%;
		content: "\E64B";
	}
}

.attendees__refresh-button {
	@include font-tabs();
	position: absolute;
	bottom: 0;
	right: 0;
	padding: 20px;
	padding-bottom: 10px;
	padding-top: 10px;
	margin-right: 2px;
	cursor: pointer;

	.spinner {
		position: absolute;
		width: 22px;
		height: 22px;
		opacity: 1;
		top: 4px;
		left: 50%;
    transform: translateX(-50%);
		&:before {
			width: 22px;
			height: 22px;
			border: 1px solid #CCC;
			border-top-color: #FFF;
		}
	}
}

.attendee-list-item--ticket {
	.attendee-list-item__tier {
		background-color: $c-ticket;
	}
}

.attendee-list-item--rsvp {
	.attendee-list-item__tier {
		background-color: $c-rsvp;
	}
}

.attendee-list-item--guestlist {
	.attendee-list-item__tier {
		background-color: $c-guest;
	}
}

.attendee-list-item--checked_in {
	color: #CCC;
	.checkmark-icon {
    display: inline-block;
    position: absolute;
    right: 30px;
		top: 19px;
	}

	.attendee-list-item__tier {
		background-color: #CCC;
	}
}

.attendee-list-item__tier {
	@include font-attendee-tier-stamp();
	position: absolute;
	right: 28px;
	top: 19px;
	background-color: $c-green;
	display: inline-block;
	padding-bottom: 1px;
	padding-top: 4px;
	padding-left: 10px;
	padding-right: 10px;
	text-transform: uppercase;
	border-radius: 3px;
}

.search-list-container {
	position: absolute;
	width: 100%;
	height: calc(100% - 70px);
	top: 70px;
	left: 0;
	z-index: 1;
	pointer-events: none;
	&.enabled {
		pointer-events: all;
	}
}

.attendees-search-list {
	border-top: 1px solid #e5e5e5;
	height: 100%;
	background-color: #FFF;

	.spinner {
		position: absolute;
		top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    margin-top: -86px;
		opacity: 1;
    transform: translateX(-50%);
		&:before {
			width: 50px;
			height: 50px;
			border: 1px solid #CCC;
			border-top-color: #FFF;
		}
	}
}
